import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description = '', lang = 'en', meta = [], title, image, author, type }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaImage = image || site.siteMetadata.image;
  const metaAuthor = author || site.siteMetadata.author;
  const metaType = type || 'website';

  // console.log('meta_data', {
  //   title: metaTitle,
  //   description: metaDescription,
  //   image: metaImage,
  //   author: metaAuthor,
  //   type: metaType,
  // });

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={`Carge | ${metaTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: metaImage,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        // {
        //   property: `og:url`,
        //   content: metaUrl,
        // },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta)}
    />
  );
}

export default Seo;
